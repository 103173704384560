import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export function PasswordLoginForm({onLogin, errorMessage, lang = 'en'}) {
  const [password, setPassword] = useState();

  return (
    <Modal.Dialog>
      <Modal.Header>
        <Modal.Title>{lang === 'pl' ? "Proszę wprowadzić hasło" : "Please enter your password" }</Modal.Title>
      </Modal.Header>
      <Form onSubmit={(e) => {
        e.preventDefault()
        onLogin(password)
      }}>
        <Modal.Body>
          <Form.Group controlId="enterPassword">
            <Form.Label>{lang === 'pl' ? "Hasło" : "Password"}</Form.Label>
            <Form.Control isInvalid={errorMessage !== ""} onChange={e => setPassword(e.target.value)}
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          placeholder={lang === 'pl' ? "Wprowadź hasło" : "Enter password"}/>
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary">{lang === 'pl' ? "Zaloguj" : "Login"}</Button>
        </Modal.Footer>
      </Form>
    </Modal.Dialog>
  )
}