import {VIMEO_EMBED_URL_REGEX, YOUTUBE_EMBED_URL_REGEX} from "../../utils/common";

export function ExternalPlayer({mediaPath, mediaType}) {

  return isMediaPathValid(mediaPath)
    && <div style={{padding: "56.25% 0 0 0", position: "relative"}}>
      <iframe src={addIframeParams(mediaPath, mediaType)}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              title="vimeo-player"
              style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}
      >
      </iframe>
    </div>
}

const isMediaPathValid = src => {
  if (!src.startsWith("https://")) {
    return false
  }

  return [VIMEO_EMBED_URL_REGEX, YOUTUBE_EMBED_URL_REGEX].some((regex) => regex.test(src));
}

const addIframeParams = (mediaPath, mediaType) => {
  const ytParams = [
    'modestbranding=1',
    'rel=0',
  ]

  return mediaType === 'youtube' ? `${mediaPath}?${ytParams.join('&')}` : mediaPath;
}
