import React from "react";

export function ChatBox({chatParams}) {
    const getChatParamsStr = (params) => {
        if (!params || params === "" || typeof params !== "string") {
            return ""
        }
        if (params.indexOf(',') === -1) {
            return ""
        }
        const [id, tag] = params.split(",")

        if (!id.match(/^\d{1,20}$/)) {
            return ""
        }

        if (!tag.match(/^[A-Z0-9]{1,20}$/i)) {
            return ""
        }

        return `boxid=${id}&boxtag=${tag}`
    }

    const chatParamsStr = getChatParamsStr(chatParams)

    return chatParamsStr !== ""
        && <iframe className="viewer-box h-100 mt-xl-0 mt-3"
                   title="chat"
                   src={`https://www5.cbox.ws/box/?${chatParamsStr}`}
                   width="100%"
                   height="450"
                   allow="autoplay" frameBorder="0" marginHeight="0" marginWidth="0"
                   scrolling="auto"/>

}