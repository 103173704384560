import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import QuestionApi from "../../../api/QuestionApi";
import {t, Trans} from "@lingui/macro";

export function QuestionsBox({
                               token,
                               streamId,
                               rows = 3,
                               requireAuthorName,
                               requireAuthorCompany,
                             }) {
  const maxQuestionLength = process.env.REACT_APP_MAX_QUESTION_LENGTH || 256
  const maxAuthorNameLength = 100;
  const maxAuthorCompanyLength = 150;

  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [content, setContent] = useState("");
  const [contentErrMsg, setContentErrMsg] = useState("");

  const [authorName, setAuthorName] = useState("");
  const [authorNameErrMsg, setAuthorNameErrMsg] = useState("");

  const [authorCompany, setAuthorCompany] = useState("");
  const [authorCompanyErrMsg, setAuthorCompanyErrMsg] = useState("");

  const [submitInProgress, setSubmitInProgress] = useState(false);

  async function submitQuestion(e) {
    e.preventDefault()
    clearErr()

    const isFormValid = validateForm()
    if (!isFormValid) {
      return
    }

    setSubmitInProgress(true)
    setStatus(t`Wysyłanie`)

    try {
      await QuestionApi.addQuestion(streamId, token, content, authorName, authorCompany)
      setContent("")
      setStatus(t`Pytanie zostało wysłane`)
      setSubmitInProgress(false)
    } catch (e) {
      setStatus("")
      setErrorMessage(t`Nie udało się wysłać pytania, spróbuj ponownie`)
      setSubmitInProgress(false)
    }
  }

  function validateForm() {
    let isValid = true

    if (!content || content.length < 0) {
      setContentErrMsg(t`Pytanie nie może być puste`)
      isValid = false
    } else if (content.length > maxQuestionLength) {
      setContentErrMsg(t`Pytanie jest zbyt długie`)
      isValid = false
    }

    if (requireAuthorName) {
      if (!authorName || authorName.length < 0) {
        setAuthorNameErrMsg(t`Podanie imienia i nazwiska jest obowiązkowe`)
        isValid = false
      } else if (authorName.length > maxAuthorNameLength) {
        setAuthorNameErrMsg(t`Zbyt długie imię i nazwisko`)
        isValid = false
      }
    }

    if (requireAuthorCompany) {
      if (!authorCompany || authorCompany.length < 0) {
        setAuthorCompanyErrMsg(t`Podanie nazwy redakcji jest obowiązkowe`)
        isValid = false
      } else if (authorCompany.length > maxAuthorCompanyLength) {
        setAuthorCompanyErrMsg(t`Zbyt długa nazwa redakcji`)
        isValid = false
      }
    }

    return isValid
  }

  function clearErr() {
    setErrorMessage("")
    setContentErrMsg("")
    setAuthorNameErrMsg("")
    setAuthorCompanyErrMsg("")
  }

  function touchField(fieldErrSetter) {
    return () => {
      fieldErrSetter("")
      setErrorMessage("")
      setStatus("")
    }
  }

  return <Form className="viewer-box questions flex-grow-1 mt-xl-0 mt-3"
               onSubmit={async e => await submitQuestion(e)}>

    <Form.Group onChange={touchField(setContentErrMsg)} controlId="questionContent">
      <Form.Label><Trans>Zadaj pytanie</Trans></Form.Label>
      <Form.Control size="sm"
                    maxLength={maxQuestionLength}
                    as="textarea"
                    rows={rows}
                    value={content}
                    isInvalid={contentErrMsg !== ""}
                    onChange={e => setContent(e.target.value)}/>
      <Form.Text muted>
        <Trans>Pozostało {maxQuestionLength - content.length} znaków {status && "| " + status}</Trans>
      </Form.Text>
      <FormControl.Feedback type="invalid">
        {contentErrMsg}
      </FormControl.Feedback>
    </Form.Group>

    {requireAuthorName &&
      <Form.Group onChange={touchField(setAuthorNameErrMsg)} controlId="questionAuthorName" className="author-name">
        <Form.Label><Trans>Imię i nazwisko</Trans></Form.Label>
        <Form.Control isInvalid={authorNameErrMsg !== ""}
                      onChange={e => setAuthorName(e.target.value)}
                      name="authorName"
                      type="text"
                      maxLength={maxAuthorNameLength}
                      placeholder={t`Podaj imię i nazwisko`}/>
        <Form.Control.Feedback type="invalid">
          {authorNameErrMsg}
        </Form.Control.Feedback>
      </Form.Group>}

    {requireAuthorCompany &&
      <Form.Group onChange={touchField(setAuthorCompanyErrMsg)} controlId="questionAuthorCompany"
                  className="author-company">
        <Form.Label><Trans>Redakcja</Trans></Form.Label>
        <Form.Control isInvalid={authorCompanyErrMsg !== ""}
                      onChange={e => setAuthorCompany(e.target.value)}
                      name="authorCompany"
                      type="text"
                      maxLength={maxAuthorCompanyLength}
                      placeholder={t`Podaj nazwę redakcji`}/>
        <Form.Control.Feedback type="invalid">
          {authorCompanyErrMsg}
        </Form.Control.Feedback>
      </Form.Group>}

    {errorMessage && <div className="invalid-feedback d-block mb-3">{errorMessage}</div>}

    <Button variant="light" type="submit" disabled={submitInProgress}><Trans>Wyślij</Trans></Button>
  </Form>
}
