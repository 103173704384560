/** @jsxRuntime classic */
// Temporary workaround for IE11 support: https://github.com/facebook/create-react-app/issues/9906
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import {LocaleContext} from "./locales/LocaleContext";
import {i18n} from '@lingui/core'
import {I18nProvider} from '@lingui/react'
import {en, pl, uk} from 'make-plural/plurals'

import {messages as plMessages} from './locales/pl/messages'
import {messages as enMessages} from './locales/en/messages'
import {messages as ukMessages} from './locales/uk/messages'

// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// CSS overrides
import './override.css';

i18n.loadLocaleData({
  pl: {plurals: pl},
  en: {plurals: en},
  uk: {plurals: uk},
})
i18n.load({
  pl: plMessages,
  en: enMessages,
  uk: ukMessages,
})
i18n.activate('pl')

function LocalizedApp() {
  const [locale, setLocale] = useState('pl');

  useEffect(() => {
    i18n.activate(locale)
  });

  // NOTE: forceRenderOnLocaleChange={true} can be added to I18nProvider to avoid issues caused by re-render
  return (
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <LocaleContext.Provider value={{locale, setLocale}}>
          <App/>
        </LocaleContext.Provider>
      </I18nProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(
  <LocalizedApp/>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
