import Spinner from "react-bootstrap/Spinner";
import React from "react";
import "./Widgets.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

export function FullscreenSpinner() {
  return <div className="vh-80 align-items-center d-flex justify-content-center">
    <Spinner role="status" variant="secondary" animation="border"/>
  </div>
}

export function ErrorPage({message, header}) {
    return (<Row className="justify-content-center">
        <Col md="6" className="viewer-box mt-4">
            <Alert variant="warning">
                <Alert.Heading>{header || 'Transmisja nie może zostać uruchomiona'}</Alert.Heading>
                <p>{message}</p>
            </Alert>
        </Col>
    </Row>)
}