import {postRequest} from "../components/Common/HttpFunctions";

const AnalyticsApi = {
  async sendWatchReport(token, eventId, accessCode, openTab) {
    let reportResponse = await postRequest(
      `${process.env.REACT_APP_ANALYTICS_API_URL}/report`,
      { accessCode, openTab},
      token
    )

    if (reportResponse.ok) {
      return reportResponse.text()
    } else if (reportResponse.status === 401) {
      throw new Error("Invalid token")
    } else {
      throw new Error("Report sending failed")
    }
  },

}

export default AnalyticsApi
