import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {ReactComponent as ArrowRepeatIcon} from "bootstrap-icons/icons/arrow-repeat.svg";
import Alert from "react-bootstrap/Alert";
import {QuestionsBox} from "./QuestionsBox";
import React from "react";
import PlaceholderImage from "./placeholder.jpg";
import {JwPlayer} from "../../Players/JwPlayer";
import {ExternalPlayer} from "../../Players/ExternalPlayer";
import {ChatBox} from "./ChatBox";
import {Trans} from "@lingui/macro";

export function StreamBox(props) {
  return <>
    <Row>
      <Col xl={8} md={12}>
        <div className="viewer-box">
          <Media mediaType={props.stream.mediaType} mediaPath={props.stream.mediaPath}
                 jwPlayerKey={props.jwPlayerKey}/>
          <div className="d-flex justify-content-end align-items-center mt-3">
            <Button className="flex-shrink-0"
                    disabled={props.disableRefreshBtn}
                    onClick={props.onRefreshClick}
                    variant="light">
              <ArrowRepeatIcon className="mr-2"/>
              <span className="align-text-top"><Trans>Odśwież</Trans></span>
            </Button>
          </div>
        </div>
        {props.stream.announcement && <div className="viewer-box mt-3">
          <Alert variant="info" className="mb-0">
            {props.stream.announcement}
          </Alert>
        </div>}
      </Col>

      {((props.stream.chatEnabled && props.stream.questions.enabled) || (props.stream.chatEnabled && !props.stream.questions.enabled)) &&
      <Col xl={4} md={12} className="d-lg-flex flex-row flex-wrap align-content-between">
        <ChatBox chatParams={props.stream.chatParams}/>
      </Col>}

      {props.stream.questions.enabled && !props.stream.chatEnabled &&
      <Col xl={4} md={12} className="d-lg-flex flex-row flex-wrap align-content-between">
        <QuestionsBox
          rows="6"
          token={props.token}
          streamId={props.stream.streamId}
          requireAuthorName={props.stream.questions.requireAuthorName}
          requireAuthorCompany={props.stream.questions.requireAuthorCompany}
        />
      </Col>
      }
    </Row>
    {props.stream.chatEnabled && props.stream.questions.enabled &&
    <Row>
      <Col className="mt-4">
        <QuestionsBox
          token={props.token}
          streamId={props.stream.streamId}
          requireAuthorName={props.stream.questions.requireAuthorName}
          requireAuthorCompany={props.stream.questions.requireAuthorCompany}
        />
      </Col>
    </Row>}
  </>;
}

function Media({mediaType, mediaPath, jwPlayerKey}) {
  switch (mediaType) {
    case "img_placeholder":
      return <img alt="Stream will begin soon"
                  className="img-fluid"
                  src={mediaPath || PlaceholderImage}/>
    case "playlist":
      return <JwPlayer key={jwPlayerKey} playlistUrl={mediaPath}/>
    case "youtube":
    case "vimeo":
      return <ExternalPlayer mediaPath={mediaPath} mediaType={mediaType}/>
    default:
      return <div/>;
  }
}
