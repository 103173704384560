import React from "react";
import Container from "react-bootstrap/Container";
import {withRouter} from "react-router-dom";
import StreamApi from "../../api/StreamApi";
import TokenApi from "../../api/TokenApi";
import "./StreamPanel.css";
import ReactGA from "react-ga4";
import {StreamBox} from "./Common/StreamBox";
import {Header, PanelStates, StateInfo} from "./Common/Common";

class StreamPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      panelState: PanelStates.SPINNER,
      passwordErrorMessage: "",
      refreshInProgress: false,
      playerKey: 0,
    };

    this.refreshMedia = this.refreshMedia.bind(this)
    this.verifyPassword = this.verifyPassword.bind(this)
  }

  async componentDidMount() {
    const TOKEN_EXPIRY_TIME = 15 * 60 * 60 * 1000;
    this.refreshTimeoutHandle = setTimeout(() => window.location.reload(), TOKEN_EXPIRY_TIME)

    document.title = "Uplive"
    document.body.style.backgroundColor = "#ECEFF3"

    const {accessCode, streamName} = this.props.match.params;
    await this.loadMedia(this.props.accessType, accessCode, streamName);
  }

  componentWillUnmount() {
    clearInterval(this.sendReportHandle)
    clearInterval(this.sendAnalyticsHandle)
    clearTimeout(this.refreshTimeoutHandle)
    this.sendReportHandle = null
    this.sendAnalyticsHandle = null
    this.refreshTimeoutHandle = null
  }

  async loadMedia(accessType, accessCode, streamName, playerKey = 0) {
    // Use access code to open stream
    if (accessType === "byCode") {
      try {
        const stream = await this.loadByAccessCode(accessCode);
        this.setState({
          panelState: PanelStates.READY,
          refreshInProgress: false,
          playerKey,
          stream
        });
        document.title = stream.streamName

        this.reportPageViewToGa(stream.streamName)
        this.startReportingToGa()
        this.startSendingWatchReportForAccessCode(accessCode)
      } catch (e) {
        this.setState({panelState: PanelStates.INVALID_ACCESS_CODE})
      }
      // Use name and password to open stream
    } else if (accessType === "byName") {
      try {
        const stream = await this.loadByName(streamName, '')
        this.setState({
          panelState: PanelStates.READY,
          refreshInProgress: false,
          playerKey,
          stream
        });
        document.title = stream.streamName
        this.reportPageViewToGa(stream.streamName)
        this.startSendingWatchReports();
      } catch (e) {
        if (e.message === "Password required") {
          this.setState({panelState: PanelStates.VIEWER_LOGIN_FORM})
        } else if (e.message === "Stream not found") {
          this.setState({panelState: PanelStates.INVALID_STREAM_NAME})
        }
      }
    }
  }

  async loadByAccessCode(accessCode) {
    const token = await TokenApi.getByAccessCode(accessCode);
    this.setState({token})

    return StreamApi.getByAccessCode(accessCode, token);
  }

  async loadByName(name, password) {
    const token = await TokenApi.getByStreamName(name, password)
    this.setState({token})

    return StreamApi.getByName(name, token);
  }

  async verifyPassword(password) {
    this.setState({panelState: PanelStates.SPINNER})
    const {streamName} = this.props.match.params;
    try {
      const stream = await this.loadByName(streamName, password);

      this.setState({panelState: PanelStates.READY, stream});
      document.title = stream.streamName
      this.reportPageViewToGa(stream.streamName)
      this.startSendingWatchReports()
    } catch (e) {
      if (e.message === "Invalid password") {
        this.setState({panelState: PanelStates.VIEWER_LOGIN_FORM})
        this.setState({passwordErrorMessage: "Niepoprawne hasło"})
      }
    }
  }

  async refreshMedia() {
    this.setState({refreshInProgress: true})
    const {accessCode, streamName} = this.props.match.params;
    const {token} = this.state

    const accessType = this.props.accessType
    try {
      const stream = await (() => {
        if (accessType === "byCode") {
          return StreamApi.getByAccessCode(accessCode, token)
        } else if (accessType === "byName") {
          return StreamApi.getByName(streamName, token)
        }
      })()
      document.title = stream.streamName
      this.setState(prevState => ({
        playerKey: prevState.playerKey + 1,
        stream
      }))
    } catch (e) {
      if (e.message === "Invalid token") {
        window.location.reload()
      }
    } finally {
      this.setState({refreshInProgress: false})
    }
  }

  reportPageViewToGa(pageTitle) {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: pageTitle,
    });
  }

  startReportingToGa() {
    const reportWatchingToAnalytics = () => {
      ReactGA.event({
        category: 'User',
        action: 'Viewer panel open',
        nonInteraction: false
      });
    }

    this.sendAnalyticsHandle = setInterval(
      reportWatchingToAnalytics,
      120 * 1000
    )
  }

  startSendingWatchReportForAccessCode(accessCode) {
    const sendReport = token => async () => {
      try {
        const response = await StreamApi.sendWatchReport(token, this.state.stream.streamId, accessCode)
        if (response.tokenStatus !== 'access_code_ok') {
          clearInterval(this.sendReportHandle);
          clearInterval(this.sendAnalyticsHandle);
          this.setState({panelState: PanelStates.INVALIDATED_TOKEN, stream: {}})
          return
        }
        if (response.announcement !== this.state.stream.announcement) {
          const {announcement} = response
          this.setState(prevState => ({stream: {...prevState.stream, announcement}}))
        }
      } catch (e) {
        if (e.message === "Invalid token") {
          window.location.reload()
        }
      }
    }

    this.sendReportHandle = setInterval(
      sendReport(this.state.token),
      process.env.REACT_APP_WATCH_REPORT_INTERVAL * 1000
    )
  }

  startSendingWatchReports() {
    const sendReport = token => async () => {
      try {
        const response = await StreamApi.sendWatchReport(token, this.state.stream.streamId)
        if (response.announcement !== this.state.stream.announcement) {
          const {announcement} = response
          this.setState(prevState => ({stream: {...prevState.stream, announcement}}))
        }
      } catch (e) {
        if (e.message === "Invalid token") {
          window.location.reload()
        }
      }
    }

    this.sendReportHandle = setInterval(
      sendReport(this.state.token),
      process.env.REACT_APP_WATCH_REPORT_INTERVAL * 1000
    )
  }

  render() {
    let {stream, playerKey} = this.state
    let customCss = window._env_?.config?.streams?.find(style => style.id === stream?.streamId)?.css

    return (
      <Container fluid="lg" className="mb-4">
        <StateInfo panelState={this.state.panelState} onLogin={this.verifyPassword}
                   passwordErrorMsg={this.state.passwordErrorMessage}/>
        {this.state.panelState === PanelStates.READY &&
          <>
            {customCss && <style>{customCss}</style>}
            <Header title={stream.streamName}
                    logoUrl={stream.logoUrl}/>
            <StreamBox stream={stream}
                       jwPlayerKey={playerKey}
                       disableRefreshBtn={this.state.refreshInProgress}
                       onRefreshClick={this.refreshMedia}
                       token={this.state.token}/>
          </>
        }
      </Container>
    );
  }
}

export default withRouter(StreamPanel);
