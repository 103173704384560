import {getRequest, patchRequest, postRequest} from "../components/Common/HttpFunctions";
import {questionFromJson} from "../components/ModeratorPanel/model/Question";

const QuestionApi = {
  async getQuestions(streamId, token) {
    let response = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/streams/${streamId}/questions`,
      token
    )

    if (response.ok) {
      return (await response.json()).map(q => questionFromJson(q))
    } else if (response.status === 404) {
      throw new Error("Stream not found")
    } else if (response.status === 401) {
      throw new Error("Invalid password");
    } else {
      throw new Error("Stream fetch error")
    }
  },

  async acceptQuestion(streamId, questionId, token) {
    let response = await patchRequest(`${process.env.REACT_APP_API_URL}/api/streams/${streamId}/questions/${questionId}`,
      {accepted: true},
      token
    )

    if (!response.ok) {
      throw new Error("Accepting question failed")
    }
  },

  async addQuestion(streamId, token, content, authorName = null, authorCompany = null) {
    let response = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/streams/${streamId}/questions`,
      {content, authorName, authorCompany},
      token
    )

    if (!response.ok) {
      throw new Error()
    }
  }
};

export default QuestionApi
