export function getRequest(url, token) {
  return fetch(url,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
}

export function postRequest(url, data, token) {
  return genericRequest("POST", url, token, data)
}

export function patchRequest(url, data, token) {
  return genericRequest("PATCH", url, token, data)
}

export function deleteRequest(url, token) {
  return genericRequest("DELETE", url, token)
}

export function deleteRequestWithData(url, data, token) {
  return genericRequest("DELETE", url, token, data)
}

function genericRequest(method, url, token, data={}) {
  return fetch(url,
    {
      method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    })
}
