import AnalyticsApi from "../api/AnalyticsApi";

const INTERVAL_MILLIS = process.env.REACT_APP_ANALYTICS_WATCH_REPORT_INTERVAL * 1000
const INTERVAL_OVERTAKE_TOLERANCE_MILLIS = 1000;

export class ExternalAnalyticsReporter {
  #activeTab
  #sendExternalAnalyticsReportHandle

  #lastReportTime = 0;

  startReporting({token, accessCode, eventId}, initialTab) {
    if (!process.env.REACT_APP_ANALYTICS_WATCH_REPORT_INTERVAL || !process.env.REACT_APP_ANALYTICS_API_URL) {
      return;
    }

    if (!window._env_?.config?.events?.find(configEvent => configEvent.id === eventId)?.enableStats) {
      return;
    }

    this.#activeTab = initialTab;

    const reportToExternalAnalytics = async () => {
      try {
        // skip if trying to send a report sooner than interval - tolerance
        if (new Date().getTime() < (this.#lastReportTime + INTERVAL_MILLIS - INTERVAL_OVERTAKE_TOLERANCE_MILLIS)) {
          return
        }

        this.#lastReportTime = new Date().getTime();
        await AnalyticsApi.sendWatchReport(token, eventId, accessCode, this.#activeTab)
      } catch (error) {
      }
    }

    const scheduleNextAfterReporting = async () => {
      await reportToExternalAnalytics();
      this.#sendExternalAnalyticsReportHandle = setTimeout(scheduleNextAfterReporting, INTERVAL_MILLIS)
    }

    this.#sendExternalAnalyticsReportHandle = setTimeout(
      scheduleNextAfterReporting,
      INTERVAL_MILLIS
    )
  }

  stopReporting() {
    if (this.#sendExternalAnalyticsReportHandle) {
      clearTimeout(this.#sendExternalAnalyticsReportHandle)
      this.#sendExternalAnalyticsReportHandle = null;
    }
  }

  changeTab(tabId) {
    this.#activeTab = tabId;
  }
}
