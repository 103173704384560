export const formatDate = (date) => new Date(date).toLocaleString("pl")

export const URL_NAME_REGEX = /^[A-Za-z0-9_-]*$/
export const VIMEO_EMBED_URL_REGEX = /^https:\/\/([a-z_.]*\.|)vimeo\.com[a-z0-9/]*$/i;
export const YOUTUBE_EMBED_URL_REGEX = /^https:\/\/www\.youtube\.com\/embed\/[\-_a-z0-9/]+$/i;

export const URL_NAME_HINT = 'Only a-z, A-Z, 0-9, _ or -'

export const getChangedValues = (values, initialValues) => {
  return Object
    .entries(values)
    .reduce((acc, [key, value]) => {
      const hasChanged = initialValues[key] !== value

      if (hasChanged) {
        acc[key] = value
      }

      return acc
    }, {})
}

export const setBackgroundWhite = () => document.body.style.backgroundColor = "#FFFFFF"

export const NewTabLink = ({url, text}) => <a href={url} target="_blank" rel="noopener noreferrer">{text}</a>
