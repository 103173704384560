import {postRequest} from "../components/Common/HttpFunctions";

const TokenApi = {
  async getByAccessCode(accessCode) {
    let tokenResponse = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/tokens`,
      {access_code: accessCode}
    )

    if (tokenResponse.ok) {
      return (await tokenResponse.json()).token
    } else {
      throw new Error("Token error")
    }
  },

  async getByStreamName(streamName, password) {
    let tokenResponse = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/tokens`,
      {
        stream_name: streamName,
        stream_password: password
      }
    )
    if (tokenResponse.ok) {
      return (await tokenResponse.json()).token
    } else if (tokenResponse.status === 404) {
      throw new Error("Stream not found")
    } else if (tokenResponse.status === 401 && !password) {
      throw new Error("Password required");
    } else if (tokenResponse.status === 401) {
      throw new Error("Invalid password");
    } else {
      throw new Error("Token error")
    }
  },

  async getByStreamNameForModerator(streamName, password) {
    let tokenResponse = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/tokens`,
      {
        stream_name: streamName,
        moderator_password: password
      }
    )
    if (tokenResponse.ok) {
      return (await tokenResponse.json()).token
    } else if (tokenResponse.status === 404) {
      throw new Error("Stream not found")
    } else if (tokenResponse.status === 401) {
      throw new Error("Invalid password");
    } else {
      throw new Error("Token error")
    }
  },

  async getByRegistrationNameForClient(registrationName, password) {
    let response = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/tokens-managers`,
      {
        registration_name: registrationName,
        clients_password: password
      }
    )
    if (response.ok) {
      return (await response.json()).token
    } else if (response.status === 404) {
      throw new Error("Registration not found")
    } else if (response.status === 403) {
      const err = await response.json()
      if (err.message === "Registration is disabled") {
        throw new Error("Disabled registration");
      }
    } else if (response.status === 401) {
      throw new Error("Invalid password");
    } else {
      throw new Error("Token error")
    }
  },

  async getForAdmin(password) {
    let tokenResponse = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/tokens-managers`,
      {admin_password: password}
    )
    if (tokenResponse.ok) {
      return (await tokenResponse.json()).token
    } else if (tokenResponse.status === 401) {
      throw new Error("Invalid password");
    } else {
      throw new Error("Token error")
    }
  }
}

export default TokenApi
