import Button from "react-bootstrap/Button";
import React from "react";
import Modal from "react-bootstrap/Modal";

export const ConfirmationDialog = ({
                                     show,
                                     onSubmit,
                                     onClose,
                                     contentText,
                                     headerText,
                                     yesText = 'Yes',
                                     noText = 'No'
                                   }) => {

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{headerText || 'Action needed'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{contentText}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onSubmit}>
          {yesText}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          {noText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}