import React, {Suspense, useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import StreamPanel from "../ViewerPanel/StreamPanel";
import {ConfirmationService} from "../Common/ConfirmationDialog/ConfirmationService";
import {getRequest} from "../Common/HttpFunctions";
import {FullscreenSpinner} from "../Common/Widgets";
import ReactGA from 'react-ga4';
import EventPanel from "../ViewerPanel/EventPanel";

const AdminPanel = React.lazy(() => import('../AdminPanel/AdminPanel'))
const QuestionsList = React.lazy(() => import('../ModeratorPanel/ModeratorPanel'))
const RegistrationForm = React.lazy(() => import('../Registration/RegistrationForm'))
const ParticipantsList = React.lazy(() => import('../ClientPanel/ParticipantsList'))


function App() {
  if (window?._env_?.ga_id) {
    ReactGA.initialize(window._env_.ga_id);
  }

  return (
    <Router>
      <Switch>
        <Route path="/" exact children={<MainPage/>}/>
        <Route path="/admin" children={
          <Suspense fallback={<FullscreenSpinner/>}>
            <ConfirmationService><AdminPanel/></ConfirmationService>
          </Suspense>
        }/>
        <Route path="/stream/:streamName" children={<StreamPanel accessType="byName"/>}/>
        <Route path="/watch/:accessCode" children={<StreamPanel accessType="byCode"/>}/>
        <Route path="/questions/:streamName" children={
          <Suspense fallback={<FullscreenSpinner/>}>
            <QuestionsList/>
          </Suspense>
        }/>
        <Route path="/event/:accessCode" children={<EventPanel accessType="byCode"/>}/>
        <Route path="/register/:registrationName" children={
          <Suspense fallback={<FullscreenSpinner/>}>
            <RegistrationForm/>
          </Suspense>}/>
        <Route path="/registration/:registrationName" children={
          <Suspense fallback={<FullscreenSpinner/>}>
            <ConfirmationService><ParticipantsList/></ConfirmationService>
          </Suspense>}/>
        <Route children={<MainPage/>}/>
      </Switch>
    </Router>
  );
}

const MainPage = () => {
  useEffect(() => {
    fetchInfo().then(text => document.location = text)
  })

  const fetchInfo = async () => (await getRequest(`${process.env.REACT_APP_API_URL}/api/info`)).text()

  return <FullscreenSpinner/>
}


export default App;
