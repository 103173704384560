export class Question {
  constructor(questionId, createdAt, content, accepted, authorName, authorCompany) {
    this.questionId = questionId;
    this.createdAt = createdAt;
    this.content = content;
    this.accepted = accepted;
    this.authorName = authorName;
    this.authorCompany = authorCompany;
  }
}

export function questionFromJson(q) {
  return new Question(
    q.QUESTION_ID,
    q.CREATED_AT,
    q.CONTENT,
    q.ACCEPTED,
    q.AUTHOR_NAME,
    q.AUTHOR_COMPANY
  )
}