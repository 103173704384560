import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ErrorPage, FullscreenSpinner} from "../../Common/Widgets";
import {PasswordLoginForm} from "../../Common/PasswordLoginForm";

export function Header({logoUrl, title, smallMargin}) {
  return <Row className="justify-content-center">
    <Col>
      <div className={`jumbotron pt-2 jumbotron-fluid ${smallMargin ? 'pb-0 mb-3' : 'pb-2 mb-3'}`}>
        <div className="viewer-box header">
          <h1 className="display-5 mb-0">
            {logoUrl && <Logo url={logoUrl}/>}
            <span className="header__title d-inline-block align-text-bottom">{title || ""}</span>
            <span className="header__subtitle"/>
          </h1>
        </div>
      </div>
    </Col>
  </Row>;
}

export const Logo = ({url}) => <img className="header__logo mr-3" alt="" src={url}/>

export const PanelStates = {
  SPINNER: 1,
  VIEWER_LOGIN_FORM: 2,
  READY: 3,
  INVALID_STREAM_NAME: 4,
  INVALID_ACCESS_CODE: 5,
  INVALIDATED_TOKEN: 6
}

export function StateInfo({panelState, passwordErrorMsg, onLogin}) {
  return <>
    {panelState === PanelStates.SPINNER && <FullscreenSpinner/>}
    {panelState === PanelStates.INVALID_ACCESS_CODE && <ErrorPage
      message="Nieprawidłowy link dostępowy."
    />}
    {panelState === PanelStates.INVALID_STREAM_NAME && <ErrorPage
      message="Nieprawidłowy link dostępowy, transmisja o podanej nazwie nie istnieje."
    />}
    {panelState === PanelStates.INVALIDATED_TOKEN && <ErrorPage
      header="Odtwarzanie zostało przerwane"
      message="Indywidualny link został otwarty w kolejnym oknie przeglądarki. Odśwież bieżące okno aby kontynuować oglądanie tutaj albo zamknij to okno i oglądaj transmisję w nowym oknie."
    />}
    {panelState === PanelStates.VIEWER_LOGIN_FORM && <PasswordLoginForm
      lang="pl"
      onLogin={onLogin}
      errorMessage={passwordErrorMsg}
    />}
  </>;
}
