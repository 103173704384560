import React from "react";
import {loadScript} from "../../utils/LoadScript";
import Spinner from "react-bootstrap/Spinner";

export class JwPlayer extends React.Component {
  state = {jwPlayerLoaded: false}

  componentDidMount() {
    let {playlistUrl} = this.props;

    if (!window.jwplayer) {
      this.loadJwPlayerScript(playlistUrl);
    } else {
      this.loadPlayer(playlistUrl)
    }
  }

  componentWillUnmount() {
    this.player.remove();
  }

  loadPlayer(playlistUrl) {
    this.player = window.jwplayer('player').setup({
      file: playlistUrl,
      autostart: true
    })

    this.player.on('ready', () => this.setState({jwPlayerLoaded: true}))
  }

  loadJwPlayerScript(playlistUrl) {
    if (!window?._env_?.jw_player_src) {
      return
    }

    loadScript(window._env_.jw_player_src)
      .then(script => {
        this.jwPlayerScript = script;
        this.loadPlayer(playlistUrl)
      })
      .catch(err => {
        console.error(err.message);
      });
  }

  render() {
    return (
      <>
        {!this.state.jwPlayerLoaded && <Spinner
          className="d-block mx-auto mt-5"
          role="status"
          variant="secondary" animation="border"/>}
        <div id="player"/>
      </>
    )
  }
}


