import React, {useState} from "react";
import {ConfirmationDialog} from "./ConfirmationDialog";

export const ConfirmationServiceContext = React.createContext({})
export const ConfirmationService = ({children}) => {
  const [confirmationState, setConfirmationState] = useState(null)

  const awaitingPromiseRef = React.useRef({})

  const openDialog = ({headerText, contentText, catchOnClose, yesText, noText}) => {
    setConfirmationState({headerText, contentText, catchOnClose, yesText, noText})

    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = {resolve, reject}
    })
  }

  const handleClose = () => {
    if (confirmationState.catchOnClose && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null)
  }

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={{openDialog}}
        children={children}
      />
      <ConfirmationDialog
        show={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  )
}