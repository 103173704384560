import React from "react";
import {Container, Nav} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {Header, PanelStates, StateInfo} from "./Common/Common";
import {StreamBox} from "./Common/StreamBox";
import TokenApi from "../../api/TokenApi";
import EventApi from "../../api/EventApi";
import ReactGA from "react-ga4";
import {ExternalAnalyticsReporter} from "../../services/ExternalAnalyticsReporter";
import {LocaleContext} from "../../locales/LocaleContext";

class EventPanel extends React.Component {
  static contextType = LocaleContext;

  externalAnalyticsReporter;

  constructor(props) {
    super(props);
    this.state = {
      panelState: PanelStates.SPINNER,
      activeTab: 0,
      refreshInProgress: false,
      playerKey: 0,
    };

    this.refreshMedia = this.refreshMedia.bind(this)
  }

  async componentDidMount() {
    this.externalAnalyticsReporter = new ExternalAnalyticsReporter();

    const TOKEN_EXPIRY_TIME = 15 * 60 * 60 * 1000;
    this.refreshTimeoutHandle = setTimeout(() => window.location.reload(), TOKEN_EXPIRY_TIME)

    document.title = "Uplive"
    document.body.style.backgroundColor = "#ECEFF3"

    const {accessCode, eventName} = this.props.match.params;
    await this.loadEvent(this.props.accessType, accessCode, eventName);
  }

  componentWillUnmount() {
    clearInterval(this.sendReportHandle)
    this.externalAnalyticsReporter.stopReporting()
    clearInterval(this.sendAnalyticsHandle)

    clearTimeout(this.refreshTimeoutHandle)

    this.sendReportHandle = null
    this.sendAnalyticsHandle = null
    this.refreshTimeoutHandle = null
  }

  async loadEvent(accessType, accessCode, eventName, playerKey = 0) {
    try {
      const event = await this.loadByAccessCode(accessCode)
      const language = window._env_?.config?.events?.find(eventConfig => eventConfig.id === event?.eventId)?.language
      if (language && this.context?.locale !== language) {
        this.context?.setLocale(language);
        return;
      }

      this.setState({
        panelState: PanelStates.READY,
        refreshInProgress: false,
        playerKey,
        event,
        activeTab: event.defaultTab < event.tabs.length ? event.defaultTab : 0,
      });
      document.title = event.eventName

      this.reportPageViewToGa(event.eventName)
      this.startReportingToGa()
      this.externalAnalyticsReporter.startReporting({
        accessCode,
        token: this.state.token,
        eventId: event.eventId
      }, this.state.activeTab);
      this.startSendingWatchReportForAccessCode(accessCode)
    } catch (e) {
      this.setState({panelState: PanelStates.INVALID_ACCESS_CODE})
    }
  }

  async loadByAccessCode(accessCode) {
    const token = await TokenApi.getByAccessCode(accessCode);
    this.setState({token})

    return EventApi.getByAccessCode(accessCode, token);
  }

  async refreshMedia() {
    this.setState({refreshInProgress: true})
    const {accessCode} = this.props.match.params;
    const {token} = this.state

    const accessType = this.props.accessType
    try {
      const event = await (() => {
        if (accessType === "byCode") {
          return EventApi.getByAccessCode(accessCode, token)
        } else if (accessType === "byName") {
          throw new Error("not implemented")
        }
      })()
      document.title = event.eventName
      this.setState(prevState => ({
        playerKey: prevState.playerKey + 1,
        event
      }))
    } catch (e) {
      if (e.message === "Invalid token") {
        window.location.reload()
      }
    } finally {
      this.setState({refreshInProgress: false})
    }
  }

  reportPageViewToGa(pageTitle) {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: pageTitle,
    });
  }

  startReportingToGa() {
    const reportWatchingToAnalytics = () => {
      ReactGA.event({
        category: 'User',
        action: 'Event panel is open',
        label: `Tab: ${this.state.activeTab}`,
        nonInteraction: false,
      });
    }

    this.sendAnalyticsHandle = setInterval(
      reportWatchingToAnalytics,
      120 * 1000
    )
  }

  startSendingWatchReportForAccessCode(accessCode) {
    const sendReport = token => async () => {
      try {
        const response = await EventApi.sendWatchReport(token, this.state.event.eventId, accessCode)
        if (response.tokenStatus !== 'access_code_ok') {
          clearInterval(this.sendReportHandle);
          this.externalAnalyticsReporter.stopReporting();
          clearInterval(this.sendAnalyticsHandle);
          this.setState({panelState: PanelStates.INVALIDATED_TOKEN, event: {}})
          return
        }
        this.updateAnnouncements(response.announcements)
      } catch (e) {
        if (e.message === "Invalid token") {
          window.location.reload()
        }
      }
    }

    this.sendReportHandle = setInterval(
      sendReport(this.state.token),
      process.env.REACT_APP_WATCH_REPORT_INTERVAL * 1000
    )
  }

  updateAnnouncements(newAnnouncements) {
    if (newAnnouncements.length === 0) {
      return
    }

    // TODO: think of a refactor
    const streams = this.state.event.tabs.filter(tab => tab.type === "stream").map(tab => ({
      id: tab.stream.streamId,
      ann: tab.stream.announcement
    }))

    for (const stream of streams) {
      const newAnn = newAnnouncements.find(a => a.streamId === stream.id).announcement
      if (stream.ann !== newAnn) {
        this.setState(prevState => {
          let tabs = [...prevState.event.tabs]
          let index = tabs.findIndex(t => t.stream?.streamId === stream.id)
          let tab = {
            ...tabs[index]
          }

          tab.stream = {
            ...tab.stream,
            announcement: newAnn
          }

          tabs[index] = tab
          return ({event: {...prevState.event, tabs}});
        })
      }
    }
  }


  render() {
    let {event} = this.state
    let customCss = window._env_?.config?.events?.find(style => style.id === event?.eventId)?.css

    return (
      <Container fluid="lg">
        <StateInfo panelState={this.state.panelState} onLogin={null} passwordErrorMsg={null}/>
        {this.state.panelState === PanelStates.READY && event && event.tabs &&
          <>
            {customCss && <style>{customCss}</style>}
            <Header title={event?.eventName} logoUrl="" smallMargin={true}/>
            <Nav className="pt-2 pb-2 viewer-box pl-3 mb-3" variant="pills" defaultActiveKey={this.state.activeTab}
                 onSelect={key => this.changeTab(key)}>
              {event.tabs.map((tab, i) =>
                <Nav.Item key={i}>
                  <Nav.Link className="tabs-btn btn-info mr-3" eventKey={i}>{tab.title}</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <div>
              <TabContent
                item={event.tabs[parseInt(this.state.activeTab)]}
                refreshMedia={this.refreshMedia}
                refreshInProgress={this.state.refreshInProgress}
                token={this.state.token}
                playerKey={this.state.playerKey}/>
            </div>
          </>}
      </Container>
    )
  }

  changeTab(key) {
    this.setState(prev => ({
      activeTab: key,
      playerKey: prev.playerKey + 1
    }));

    this.externalAnalyticsReporter.changeTab(key);
  }
}

export default withRouter(EventPanel);

function TabContent({item, playerKey, refreshMedia, refreshInProgress, token}) {
  if (item) {
    switch (item.type) {
      case "text":
        return <div className="viewer-box" dangerouslySetInnerHTML={{__html: item.content}}/>
      case "stream":
        return <StreamBox stream={item.stream}
                          jwPlayerKey={playerKey}
                          disableRefreshBtn={refreshInProgress}
                          onRefreshClick={refreshMedia}
                          token={token}/>
      default:
        return <div/>
    }
  } else {
    return <div/>
  }
}

