import {deleteRequest, getRequest, patchRequest, postRequest} from "../components/Common/HttpFunctions";

const EventApi = {
  async getByAccessCode(accessCode, token) {
    let eventResponse = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/events?access_code=${accessCode}`,
      token
    )

    if (eventResponse.ok) {
      return eventResponse.json()
    } else if (eventResponse.status === 404) {
      throw new Error("Event not found")
    } else if (eventResponse.status === 401) {
      throw new Error("Invalid token")
    } else {
      throw new Error("Event fetch error")
    }
  },

  async getByRegistrationName(registrationName, token) {
    let response = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/events?registration_name=${registrationName}`,
      token
    )

    if (response.ok) {
      return response.json()
    } else if (response.status === 404) {
      throw new Error("Event not found")
    } else if (response.status === 401) {
      throw new Error("Invalid token")
    } else {
      throw new Error("Event fetch error")
    }
  },

  async get(id, token) {
    let eventResponse = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/events/${id}`,
      token
    )

    if (eventResponse.ok) {
      return eventResponse.json()
    } else if (eventResponse.status === 404) {
      throw new Error("Event not found")
    } else if (eventResponse.status === 401) {
      throw new Error("Invalid token")
    } else {
      throw new Error("Event fetch error")
    }
  },

  async add(event, token) {
    let response = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/events`,
      event,
      token
    )

    if (!response.ok) {
      throw new Error("adding event failed")
    } else {
      return response.json()
    }
  },

  async update(eventId, event, token) {
    let response = await patchRequest(
      `${process.env.REACT_APP_API_URL}/api/events/${eventId}`,
      event,
      token
    )

    if (!response.ok) {
      throw new Error("updating event failed")
    } else {
      return response.json()
    }
  },

  async remove(eventId, token) {
    let response = await deleteRequest(
      `${process.env.REACT_APP_API_URL}/api/events/${eventId}`,
      token
    )

    if (!response.ok) {
      throw new Error("removing event failed")
    }
  },


  async sendWatchReport(token, eventId, accessCode) {
    let reportResponse = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/events/${eventId}/reports`,
      accessCode ? {'access_code': accessCode} : {},
      token
    )

    if (reportResponse.ok) {
      return reportResponse.json()
    } else if (reportResponse.status === 401) {
      throw new Error("Invalid token")
    } else {
      throw new Error("Report sending failed")
    }
  },

  async getAll(token) {
    let eventResponse = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/events`,
      token
    )

    if (eventResponse.ok) {
      return eventResponse.json()
    } else if (eventResponse.status === 401) {
      throw new Error("Invalid token")
    } else {
      throw new Error("fetching events failed")
    }
  },

  async isRegistrationNameAvailable(registrationName, token) {
    let response = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/is-available/registration-name/${registrationName}`,
      token
    )

    if (response.ok) {
      return response.text()
    }
  }
}

export default EventApi
