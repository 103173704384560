import {deleteRequest, getRequest, patchRequest, postRequest} from "../components/Common/HttpFunctions";


const StreamApi = {
  async getByAccessCode(accessCode, token) {
    let streamResponse = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/streams?access_code=${accessCode}`,
      token
    )

    if (streamResponse.ok) {
      return streamResponse.json()
    } else if (streamResponse.status === 404) {
      throw new Error("Stream not found")
    } else if (streamResponse.status === 401) {
      throw new Error("Invalid token");
    } else {
      throw new Error("Stream fetch error")
    }
  },

  async getByName(name, token) {
    let streamResponse = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/streams?name=${name}`,
      token
    )

    if (streamResponse.ok) {
      return streamResponse.json()
    } else if (streamResponse.status === 404) {
      throw new Error("Stream not found")
    } else if (streamResponse.status === 401) {
      throw new Error("Invalid token");
    } else {
      throw new Error("Stream fetch error")
    }
  },

  async get(id, token) {
    let streamResponse = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/streams/${id}`,
      token
    )

    if (streamResponse.ok) {
      return streamResponse.json()
    } else if (streamResponse.status === 404) {
      throw new Error("Stream not found")
    } else if (streamResponse.status === 401) {
      throw new Error("Invalid token");
    } else {
      throw new Error("Stream fetch error")
    }
  },

  async sendWatchReport(token, streamId, accessCode) {
    let reportResponse = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/streams/${streamId}/reports`,
      accessCode ? {'access_code': accessCode} : {},
      token
    )

    if (reportResponse.ok) {
      return reportResponse.json()
    } else if (reportResponse.status === 401) {
      throw new Error("Invalid token");
    } else {
      throw new Error("Report sending failed")
    }

  },

  async getAll(token) {
    let streamResponse = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/streams`,
      token
    )

    if (streamResponse.ok) {
      return streamResponse.json()
    } else if (streamResponse.status === 401) {
      throw new Error("Invalid token");
    } else {
      throw new Error("Streams fetching failed")
    }
  },

  async remove(streamId, token) {
    let response = await deleteRequest(
      `${process.env.REACT_APP_API_URL}/api/streams/${streamId}`,
      token
    )

    if (!response.ok) {
      throw new Error("Stream removing failed")
    }
  },

  async add(stream, token) {
    let response = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/streams`,
      stream,
      token
    )

    if (!response.ok) {
      throw new Error("adding stream failed")
    } else {
      return response.json()
    }
  },

  async update(streamId, stream, token) {
    let response = await patchRequest(
      `${process.env.REACT_APP_API_URL}/api/streams/${streamId}`,
      stream,
      token
    )

    if (!response.ok) {
      let error;
      try {
        error = await response.json();
      } catch (e) {
        throw new Error("updating stream failed");
      }

      throw new Error(error.message ?? "updating stream failed");
    } else {
      return response.json();
    }
  },

  async isShortNameAvailable(shortName, token) {
    let response = await getRequest(
      `${process.env.REACT_APP_API_URL}/api/is-available/stream-short-name/${shortName}`,
      token
    )

    if (response.ok) {
      return response.text()
    }
  }
}

export default StreamApi
